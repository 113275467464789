import React from "react";

export interface ReviewsProps {
    className: string;
}

export default function Reviews({className}: ReviewsProps) {
    return (
        <div className="max-w-lg lg:w-[592px] h-[700px] overflow-hidden relative">
            <iframe
                className="w-full h-full rounded-md box-border"
                src="https://yandex.ru/maps-reviews-widget/132275218046?comments"
                allowFullScreen
                title="МурманКлик на карте Мурманска — Яндекс Карты">
            </iframe>
            <a
                className="absolute bottom-2 left-0 w-full text-center text-xs text-gray-400 font-sans px-4 overflow-hidden whitespace-nowrap block h-3.5"
                href="https://yandex.ru/maps/org/murmanklik/132275218046/"
                target="_blank"
                rel="nofollow">
                МурманКлик на карте Мурманска — Яндекс Карты
            </a>
        </div>)
        ;
}
