'use client';
import React, {DetailedHTMLProps, HTMLAttributes, useEffect, useState} from 'react';
import Link from 'next/link';
import {motion, useReducedMotion} from 'framer-motion';
import {useRouter} from 'next/navigation';
import {P} from './ui/P';
import {ButtonIcon} from './button-icon';
import {Icon} from './ui/icon';
import SocialIcons from "@/components/social-icons";
import Line from "@/components/ui/line";

export interface HeaderProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    className?: string;
}

interface MenuLink {
    path: string;
    name: string;
}


export const Header = ({className, ...props}: HeaderProps) => {
    const [activeLink, setActiveLink] = useState('');
    const [isOpened, setIsOpened] = useState(false);
    const router = useRouter();
    const shouldReduceMotion = useReducedMotion();

    const handleLinkClick = (link: string) => {
        setActiveLink(link);
        setIsOpened(false); // Закрывает меню после выбора опции
    };

    useEffect(() => {
        setIsOpened(false);
    }, [router]);

    const variants = {
        opened: {
            opacity: 1,
            x: 0,
            transition: {
                stiffness: 30,
            },
        },
        closed: {
            opacity: shouldReduceMotion ? 1 : 0,
            x: '100%',
        },
    };

    const linkMenu: MenuLink [] = [
        {path: '/sell', name: 'Продать'},
        {path: '/buy', name: 'Купить'},
        {path: '/', name: 'О нас'},
        {path: '/lawyer', name: 'Юридические услуги'},
    ];
    const topLinkMenu: MenuLink [] = [
        {path: '/login', name: 'Вход/Регистрацию'},
        {path: 'tel: +79113007705', name: '+7(8152)707-705'},
    ];

    return (
        <div className={`flex justify-between h-full items-center w-full mb-6`}>
            <div className="hidden md:block w-full">
                <div className="max-w-custom mx-auto flex justify-between text-md font-light text-gray-500 my-3">
                    <Link href={'/login'}
                          className={`flex items-center justify-center gap-3 hover:text-primary ${activeLink === '/login' ? 'text-primary' : ''}`}
                          onClick={() => handleLinkClick('/login')}>
                        <Icon path={'https://img.icons8.com/pastel-glyph/64/000000/user-male-circle.png'} size={'l'}
                              alt={'аккаунт Мурман Клик'} className={'p-2 opacity-50'}/>
                        Вход/регистрация
                    </Link>
                    <Link id={'tel'} href={'tel: +79113007705'}
                          className={`flex items-center justify-center gap-3 hover:text-primary ${activeLink === 'tel: +79113007705' ? 'text-primary' : ''}`}
                          onClick={() => handleLinkClick('tel: +79113007705')}>
                        <Icon path={'/call3.svg'} size={'l'} alt={'Позвонить в Мурман Клик'}
                              className={'border-secondary border rounded-full h-7 w-7 p-1'}/>
                        +7(8152) 707-705
                    </Link>
                </div>
                <Line/>
                <div className="max-w-custom mx-auto flex justify-between items-center gap-4 w-full">
                    <Icon size={'logo'} path={'/logo.svg'} alt={'агентство недвижимости Мурман Клик'}/>
                    <div className="flex gap-4 text-lg font-light text-gray-500">
                        {linkMenu.map((path, index) => (
                            <Link
                                key={index}
                                href={path.path}
                                className={`hover:text-primary ${activeLink === path.path ? 'text-primary' : ''}`}
                                onClick={() => handleLinkClick(path.path)}
                            >
                                {path.name}
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
            <div className="md:hidden w-full flex justify-between items-center px-3 mt-6">
                <Icon size={'logo'} path={'/logo.svg'} alt={'агентство недвижимости Мурман Клик'}/>
                <ButtonIcon onClick={() => setIsOpened(true)} icon={'menu'} appearance={'primary'} className="ml-auto"/>
                <motion.div
                    className={`fixed z-10 top-0 right-0 left-0 bottom-0 bg-white overflow-hidden flex justify-center`}
                    variants={variants}
                    initial='closed'
                    animate={isOpened ? 'opened' : 'closed'}
                    style={{pointerEvents: isOpened ? 'auto' : 'none'}}
                >
                    <div className='flex flex-col gap-8 bg-white text-gray-500 text-lg text-center mt-14 max-w-[350px]'>
                        {linkMenu.map((path, index) => (
                            <Link
                                key={index}
                                href={path.path}
                                className={`hover:text-primary ${activeLink === path.path ? 'text-primary' : ''}`}
                                onClick={() => handleLinkClick(path.path)}
                            >
                                {path.name.toUpperCase()}
                            </Link>
                        ))}
                        <Link href={'tel: +79113007705'} className={'flex items-center justify-center gap-3'}>
                            <Icon path={'/call.svg'} size={'l'} alt={'Позвонить в Мурман Клик'}
                                  className={'bg-secondary rounded-full p-2 '}/>
                            <P size={'l'}> +7(8152) 707-705</P>
                        </Link>
                        <P size='s' className='text-text-secondary/70 '>г. Мурманск, пр-т., Ленина д. 52 (ДЦ Аметист) офис 405, 4 этаж</P>
                        <P size='s' className='text-text-secondary/70 '>Социальные сети</P>
                        <SocialIcons className={'justify-center'}/>
                    </div>
                    <ButtonIcon icon={'close'} className='absolute top-5 right-5 h-4 w-4'
                                onClick={() => setIsOpened(false)} appearance={'primary'}/>
                </motion.div>
            </div>
        </div>
    );
};


export default Header;
