import React, {ButtonHTMLAttributes, DetailedHTMLProps} from 'react';
import {cn} from '@/lib/utils';
import Image from 'next/image';
import menu from '../public/menu.png';
import close from '../public/close.svg';

export const icons = {
    close,
    menu
};

export type IconName = keyof typeof icons;

export interface ButtonIconProps extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    icon: IconName;
    appearance: 'primary' | 'white';
}

export const ButtonIcon = ({appearance, icon, className, ...props}: ButtonIconProps): JSX.Element => {
    const IconComp = icons[icon];
    return (
        <button
            className={cn(className, {
                'w-8 h-8': appearance == 'primary',
                '': appearance == 'white',
            })}
            {...props}
        >
            <Image src={icons[icon]} alt={icon} width={40} height={40}/>
        </button>
    );
};