import(/* webpackMode: "eager" */ "/opt/app/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/app/components/about.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/app/components/footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Header"] */ "/opt/app/components/header.tsx");
;
import(/* webpackMode: "eager" */ "/opt/app/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/opt/app/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/opt/app/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/opt/app/node_modules/next/font/google/target.css?{\"path\":\"app/(site)/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
