"use client";
import Link from "next/link";
import {P} from "./ui/P";
import Line from "@/components/ui/line";
import SocialIcons from "@/components/social-icons";


export default function Footer() {
    return (
        <div className="max-w-custom mx-auto my-12 px-3">
            <div className="grid grid-cols-1 gap-12  xl:grid-cols-3">
                <div className="flex flex-col gap-4">
                    <P size='s' className='text-text-secondary/70 '>Навигация</P>
                    <Link href={'/sell'} className={` hover:text-primary `}>
                        <P size="s" className="text-gray-300"> Продать</P>
                    </Link>
                    <Link href={'/buy'} className={`hover:text-primary`}>
                        <P size="s"> Купить</P>
                    </Link>
                    <Link href={'/about'} className={`hover:text-primary `}>
                        <P size="s"> О нас</P>
                    </Link>
                    {/*<Link href={'/reviews'} className={`hover:text-primary `}>*/}
                    {/*    <P size="s"> Отзывы</P>*/}
                    {/*</Link>*/}
                </div>
                <div className="flex flex-col gap-4">
                    <P size='s' className='text-text-secondary/70'>Телефон</P>
                    <Link href={'tel: +7(911)300-7705'} className={` hover:text-primary `}>
                        <P size="s"> +7(911)300-7705</P>
                    </Link>
                    <Link href={'tel: +79118002459'} className={`hover:text-primary`}>
                        <P size="s"> +7(911) 800-2459</P>
                    </Link>
                </div>
                <SocialIcons className={''}/>
            </div>
            <div className='my-12'>
                <Line/>
                <P size='s' className='text-text-secondary/70 mt-8'>Наши услуги</P>
                <Link href={'/sell-rooms'} className={`hover:text-primary `}>
                    <P size="s" className="my-4">Продать комнату в Мурманске</P>
                </Link>
                <Link href={'/sell-doli-v-kvartire'} className={`hover:text-primary `}>
                    <P size="s" className="my-4">Продать долю в квартире</P>
                </Link>
                <Link href={'/lawyer'} className={`hover:text-primary `}>
                    <P size="s" className="my-4">Юридические услуги</P>
                </Link>
                <Line/>
            </div>
            <Link href={'/privacy'} className={`hover:text-primary `}>
                <P size="s" className="my-4">Политика конфидицальности</P>
            </Link>
            <P size="s">©️ 2024. Murmanclick.ru. Все права защищены</P>
        </div>
    );
}