import React from "react";
import cn from "classnames";
import Link from "next/link";
import {Icon} from "@/components/ui/icon";

export interface SocialIconsProps {
    className: string;
}

export default function SocialIcons({className}: SocialIconsProps) {
    return (
        <div className={cn("flex flex-row gap-4", className)}>
            <Link href={'https://vk.com/murmanclick'} rel="nofollow" className={` hover:text-primary `}>
                <Icon size="l" path={"/vk.svg"} alt={"Страница Мурман Клик в Вконтакте"}></Icon>
            </Link>
            <Link href={'https://t.me/@RealEstateInvest_bot'} rel="nofollow" className={`hover:text-primary`}>
                <Icon size="l" path={"/tg.svg"} alt={"Телеграм бот Мурман Клик"}></Icon>
            </Link>
            <Link href={'https://wa.me/79113007705'} rel="nofollow" className={`hover:text-primary `}>
                <Icon size="l" path={"/whatsapp.svg"} alt={"WhatsApp Мурман Клик"}></Icon>
            </Link>
        </div>
    );
}
