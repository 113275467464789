'use client';
import React from 'react';
import {Htag} from './ui/htag';
import {P} from './ui/P';
import Link from "next/link";
import SocialIcons from "@/components/social-icons";
import SimplePhoneForm from "@/components/form-only-phone";
import Reviews from "@/components/reviews";


export default function About() {
    return (

        <div className='max-w-custom mx-auto my-14 px-3'>
            <div className={'flex flex-col-reverse gap-8 lg:flex-row lg:justify-between mt-8'}>

                <div className={'flex flex-col gap-6 max-w-[560px]'}>
                    <Htag tag='h2' className={'mb-3'}>Контакты</Htag>
                    <div className={'flex gap-3.5'}>
                        <P size={'s'} className={'opacity-70'}>Адрес:</P>
                        <P size={'s'}>г. Мурманск, пр-т.,Ленина д. 52 (ДЦ Аметист) офис 405, 4 этаж</P>
                    </div>
                    <div className={'flex gap-3.5'}>
                        <P size={'s'} className={'opacity-70'}>Телефон:</P>
                        <Link href={'tel: +79113007705'}><P size={'s'}>+ 7(8152) 707-705 </P></Link>
                    </div>
                    <div className={'flex gap-3.5'}>
                        <P size={'s'} className={'opacity-70'}>Телефон:</P>
                        <Link href={'tel: + 79118002459'}><P size={'s'}>+ 7(911) 800-2459 </P></Link>
                    </div>
                    <div className={'flex gap-3.5'}>
                        <P size={'s'} className={'opacity-70'}>Email:</P>
                        <Link href={'tel: +79113007705'}><P size={'s'}>info@murmanclick.ru</P></Link>
                    </div>

                    <div>
                        <P size={'s'} className={'opacity-70 mb-3'}>Режим работы:</P>
                        <P size={'s'}>Пн-Пт с 9:00 до 18:00</P>
                        <P size={'s'}>Сб-Вс с 10:00 до 17:00</P>
                    </div>
                    <P className={'opacity-70'}>Социальные сети</P>
                    <SocialIcons className={''}/>
                        <P size={'l'} className={'mt-6'}>Заявка обратной связи</P>
                        <SimplePhoneForm className={''}/>
                </div>
                <div className={'flex flex-col gap-8'}>
                <Htag tag='h2' className={'mb-3'}>Отзывы</Htag>
                <Reviews className={''}/>
                </div>
            </div>
        </div>
    );
}
